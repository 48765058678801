import React from "react";

const PaperSubmission = () => {
  return (
    <div className="p-4">
      <h1 className="text-4xl font-semibold text-center m-3">
        Paper Submission
      </h1>
      <hr className="mb-4 border-black border-2 w-96 m-auto" />
      <p className="text-xl">
      The Original unpublished Research Papers, Articles & Working papers having minimum length of 8 pages on the topics related to the theme are invited for presentation/ publication in the conference proceedings.
      </p>
      <ol className="m-4 text-lg">
        <li className="list-decimal">
        Kindly ensure that your paper is formatted as per Springer Guidelines (not exceeding 12 pages written in A4 size). Please refer the attached springer template for preparation of your paper.
        </li>
        <li className="list-decimal">
          All papers must be submitted online via{" "}
          <a
            href="https://cmt3.research.microsoft.com/ICCTRDA2025"
            className="text-blue-600 font-semibold"
          >
            Microsoft CMT Submission portal
          </a>
          .
        </li>
        <li className="list-decimal">
          All submissions will be thoroughly peer-reviewed by experts based on
          originality, significance, and clarity.
        </li>
        <li className="list-decimal">
          Only papers presenting original content with novel research results or
          successful innovative applications will be considered for publication
          in the conference proceedings.
        </li>
      </ol>
      <p className="text-3xl font-semibold text-center m-3">
        Plagiarism Policy
      </p>
      <ol className="m-4 text-lg">
        <li className="list-decimal">
          The paper prior to submission should be checked for plagiarism from
          licensed plagiarism software like Turnitin / iAuthenticate etc. The
          similarity content should not exceed 15% (in any case either self
          contents or others). Further, you have to strictly implement the
          following ethical guidelines for publication.
        </li>

        <li className="list-decimal">
          Any form of self-plagiarism or plagiarism from other's work(s) should
          not be there in an article. If any model / concept / figure / table /
          data / conclusive comment by any previously published work is used in
          your article, you should properly cite a reference to the original
          work.
        </li>

        <li className="list-decimal">
          Also language of explaining it should not be same as language of the
          work from which you have adopted it. If you are using any copyrighted
          material, you should acquire prior permission from the copyright
          holder.
        </li>
      </ol>
    </div>
  );
};

export default PaperSubmission;
