import React from 'react'
import { dataAnalyticsTopics } from '../constants/track1'
import { dataManagementTopics } from '../constants/track2'
import { bigDataTopics } from '../constants/track3'
import { computationalIntelligenceTopics } from '../constants/track4'
import { communicationNetworksTopics } from '../constants/track5'

const CallForPapers = () => {
  return (
    <div>
        <h1 className='text-4xl font-semibold text-center m-3'>Call for Papers</h1>
        <hr className="mb-4 border-black border-2 w-96 m-auto"/>
        <p className='text-lg font-medium m-3 text-justify'>The scope of this conference is to provide a platform for researchers, engineers, academicians as well as industrial professionals from all over the world to present their research results and development activities in various topics of Communication Technology Research and Data Analytics. It allows participants an opportunity to discuss the recent developments in the field of solidification computer science and management and review challenges faced by the community in the 21st century. The conference consists of different tracks which are listed below as:</p>
        
        <div className="p-4 border-solid border-2 border-black w-1/2 m-auto">
        <h1 className='text-2xl font-semibold  m-3'>Track 1: Data Analytics</h1>
        <ul className='p-4 '>
        {dataAnalyticsTopics.map((t)=>{
          return <li className='list-disc'>{t}</li>
        })}
        </ul>
        </div>
        <div className="p-4 border-solid border-2 border-black my-4 w-1/2 m-auto">
        <h1 className='text-2xl font-semibold  m-3'>Track 2: Data Management</h1>
        <ul className='p-4'>
        {dataManagementTopics.map((t)=>{
          return <li className='list-disc'>{t}</li>
        })}
        </ul>
        </div>
        <div className="p-4 border-solid border-2 border-black my-4 w-1/2 m-auto">
        <h1 className='text-2xl font-semibold  m-3'>Track 3: Big Data</h1>
        <ul className='p-4'>
        {bigDataTopics.map((t)=>{
          return <li className='list-disc'>{t}</li>
        })}
        </ul>
        </div>
        <div className="p-4 border-solid border-2 border-black my-4 w-1/2 m-auto">
        <h1 className='text-2xl font-semibold  m-3'>Track 4: Computational Intelligence</h1>
        <ul className='p-4'>
        {computationalIntelligenceTopics.map((t)=>{
          return <li className='list-disc'>{t}</li>
        })}
        </ul>
        </div>
        
        <div className="p-4 border-solid border-2 border-black my-4 w-1/2 m-auto">
        <h1 className='text-2xl font-semibold  m-3'>Track 5: Communication Networks</h1>
        <ul className='p-4'>
        {communicationNetworksTopics.map((t)=>{
          return <li className='list-disc'>{t}</li>
        })}
        </ul>
        </div>
        <p className='text-lg font-medium m-3 text-justify'>The papers submitted to the conference will be peer-reviewed and published as SCOPUS indexed proceedings.</p>
        <p className='text-lg font-normal m-3 text-justify'>All papers will undergo a blind peer-review process and all accepted papers will be published in the SCOPUS indexed conference proceedings. The conference organizer encourages submissions of academic and research papers that consider the multidisciplinary /interdisciplinary themes covering the Management, Science and Social Studies, through one of the following sub-themes, although submissions of other topics for consideration are also welcome.</p>
    </div>
  )
}

export default CallForPapers