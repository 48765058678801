import React from 'react'
import { Button } from '@material-tailwind/react'
import Impdates from '../Utilities/Impdates'
import Notices from '../Utilities/Notices'
import Downloads from '../Utilities/Downloads'
import { Link } from 'react-router-dom';
import { Component } from '../Utilities/Carousel';
import du from "../images/Logos/du.jpg";
import rj from "../images/Logos/rajguru.png";
import dut from "../images/Logos/duytan.png";


const Home = () => {
  return (
    <div className='p-4'>
      <Component/>
      <h1 className='text-3xl text-center font-bold mt-4'>About Conference</h1>
      <hr className="mt-2 border-black border-2 w-96 m-auto"/>
      <p className='text-justify font-semibold mt-4'>ICCTRDA-2025 is organised with the objective of bringing together innovative scientists, professors, research scholars, students and industrial experts in the field of Sciences, Communication Technology Methods and Techniques to a common platform. The primary goal of the conference is to promote the exchange of innovative scientific information between researchers, developers, engineers, students, and practitioners. Overall the conference will provide the researchers and attendees with prospects for national and international collaboration and networking among universities and institutions from India and abroad for promoting research. ICCTRDA 2025 will be jointly organized by Duy Tan University, Vietnam on 21st - 22nd November 2025. <br/><br/>
      {/* The proceedings of ICCTRDA 2025 will be published by IEEE  Computer Society (IEEE Xplore) indexed by all reputed databases like Scopus, EI, WoS. */}
      </p>
      <Button color='blue' className='mt-4 p-3 '><Link to={'https://cmt3.research.microsoft.com/ICCTRDA2025'}>Paper Submission Link</Link></Button>
      <h1 className='text-3xl text-center font-bold mt-8'>ICCTRDA Academic Partners</h1>
      <hr className="mt-2 border-black border-2 w-96 m-auto mb-4"/>

      <div className='w-[80%] mx-auto flex flex-wrap justify-evenly mb-16'>
        {/* <img src={du} className='w-40 h-auto m-4'/> */}
        {/* <img src={rj} className='w-40 h-auto m-4'/> */}
        <img src={dut} className='w-40 h-auto m-4'/>

      </div>
      <hr className="mt-2 border-black border-2 w-96 m-auto"/>
      <div></div>
      <br/><br/>
      <div className='flex flex-col md:flex-row justify-around my-8'>
      <Impdates />
      <Downloads/>
      <Notices/>
      </div>
      
    </div>
  )
}

export default Home