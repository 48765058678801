import React from 'react'
import RegTable from '../Utilities/RegTable'
import { Button } from '@material-tailwind/react';
import { Link } from 'react-router-dom';

const CAP_ROWS = [
  {
    Category: "Research Scholar/Student (Physical Mode)",
    EarlyRegistration: "USD 300",
    LateRegistration: "USD 350",
  },
  {
    Category: "Research Scholar/Student (Online Mode)",
    EarlyRegistration: "USD 180",
    LateRegistration: "USD 210",
  },
  {
    Category: "Standard Authors (Physical Mode)",
    EarlyRegistration: "USD 350",
    LateRegistration: "USD 400",
  },
  {
    Category: "Standard Authors (Online Mode)",
    EarlyRegistration: "USD 210",
    LateRegistration: "USD 240",
  },
  
];

const CPP_ROWS=[
  {
    Category: "Research Scholar/Student Participants",
    EarlyRegistration: "USD 150",
    LateRegistration: "USD 180",
  },
  {
    Category: "Standard Participants",
    EarlyRegistration: "USD 180",
    LateRegistration: "USD 210",
  },
]

const Registration = () => {
  return (
    <div className='p-4'>
        <h1 className='text-4xl font-semibold text-center m-3'>Registrations</h1>
        <hr className="mb-4 border-black border-2 w-96 m-auto"/>
        <p className='text-2xl font-medium text-left m-3'>Author/Co-Author Registration Fee Includes:</p>
        <ol className='text-lg ml-10 list-decimal'>
            <li>Access to Keynote talks</li>

            <li>Access to paper presentation sessions</li>

            <li>Networking Sessions</li>

            <li>Conference lunch and refreshment*</li>

            <li>Tea and Coffee Breaks*</li>

            <li>Conference Kit*</li>

            <li>Pre-Proceeding (collection of Abstracts only)*</li>

            <li>Participation Certificate</li>
        </ol>
        <p className='font-semibold m-4'>**The Conference will be in Hybrid mode</p>
        <h3 className='text-2xl font-medium text-center m-3'>Conference Accepted Paper Registration Fee For ICCTRDA-2025</h3>
        <RegTable rows={CAP_ROWS}/>
        <ul className='text-base font-medium text-center'>
          <li>(*Standard Paper size : 6 pages. Over length paper size will be a maximum of 8 pages with $20 per extra page.)
        
          </li>
          <li>
          (**Research Student Author - Those authors who are currently prusuing PHD, Masters degree in a research organisation/University comes under this category. ) <br/><br/>
 If you have any concerns regarding the registration policy or fees please feel to write us at icctrda.congress@gmail.com.<br/><br/>
          </li>
        </ul>
        <h3 className='text-2xl font-medium text-center m-3'>Conference Participant Registration Fee for ICCTRDA-2025</h3>
        <RegTable rows={CPP_ROWS}/>
        <h3 className='text-2xl font-medium text-center m-3'>Register Online</h3>
        <div className='flex flex-col items-center w-1/2 m-auto'>
        <Link to="/checkout"><Button className='p-4 w-auto bg-[#343a40]'>Registration - via Stripe</Button></Link><br/>
        <Link to="https://rzp.io/rzp/OdISRPva"><Button className='p-4 w-auto bg-[#343a40]'>Registration - via Razorpay <br/>(only for SAARC Nations)</Button></Link><br/>
        <Link to="https://paypal.me/adroidconference?country.x=IN&locale.x=en_GB"><Button className='p-4 w-auto bg-[#343a40]'>Registration - Via PayPal</Button></Link>
        <p className='text-base font-medium my-4'>NOTE: The conference is non-residential and delegates are kindly requested to make their own arrangement for accommodation. If you have any query, please drop a mail to . All participants and authors abide by the [TERMS AND CONDITIONS] as per the organizations guidelines.</p>
        </div>
        
    </div>
  )
}

export default Registration