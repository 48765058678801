import React from 'react'

const CallForSpSess = () => {
  return (
    <div className='p-4'>
       <h1 className='text-4xl font-semibold text-center m-3'>Call For Special Sessions</h1>
       <hr className="mb-4 border-black border-2 w-96 m-auto"/>
       <p className='text-2xl font-medium text-center m-3'>Invitation to conduct special session in ICCTRDA 2025</p>
       <p className='text-lg font-medium t m-3'>Dear Sir/Madam</p>
      <p className='text-lg m-3 text-justify'>Greeting from ICCTRDA-2025!<br/><br/>

ICCTRDA Conference team invites you to organize the special session in the International Conference on Communication Technology Research and Data Analytics (ICCTRDA-2025), on 21st – 22nd November 2025 of your research domain. We are diligently working to bring some of the most knowledgeable researchers from all over the world along with the leaders from the industry to explore the important topics of research. Conference is organised with the objective of bringing together innovative scientists, professors, research scholars, students and industrial experts in the field of Computing and Communication to a common forum. The primary goal of the conference is to promote the exchange of innovative scientific information between researchers, developers, engineers, students, and practitioners. Another goal is to promote the transformation of fundamental research into institutional and industrialized research and to convert applied exploration into real time application. Kindly increase the value of the conference by being part of this conference as a session chair and heading your own session in this esteemed conference. We are aware about your networking skills and of having an eye to identify quality research papers.</p>
<p className='text-2xl font-medium text-center m-3'>Highlights of the conference and Special Session Benefits:</p>
<ol className='m-4 text-lg'>
  
  <li className='list-decimal'> Proceedings in the reputed Elsevier Procedia (Scopus) indexed proceedings.</li>


<li className='list-decimal'> You will get 15% of the total fees collected in your session.</li>


<li className='list-decimal'> The minimum number of registrations should be 8.</li>


<li className='list-decimal'> Extended papers in SCI/Scopus Digital Library journals. We have already got approval from some Scopus and high quality journals.</li>


<li className='list-decimal'> High quality publishing houses are part of this conference like Springer, Elsevier, IGI Global and many more.</li>


<li className='list-decimal'> Opportunity to be Session chair at the conference.</li>

<li className='list-decimal'>Many international and national key note speakers will be delivering their lectures on latest innovative technologies in the conference.</li>
</ol>
    </div>
  )
}

export default CallForSpSess;