import React from 'react';
// import clg from "../images/bg/duytancampus.jpg"
import clg from "../images/bg/rajguru-building.gif"

import CarouselComponent from '../Utilities/Carousel';

const Venue = () => {
  return (
    <div className='w-[80%] m-auto'>
    <h1 className='text-4xl font-semibold text-center m-3'>Conference Venue</h1>
    <hr className="mb-4 border-black border-2 w-96 m-auto"/>
    <p className='text-2xl font-medium text-justify m-3'>
        We are located at :-
    </p>
    <img src={clg} className=' m-4 rounded-lg shadow-lg w-96'/>
    <p className='text-xl font-medium text-justify mt-3 mx-3'>Address: Shaheed Rajguru College of Applied Sciences for Women, Vasundhara Enclave, Delhi, India</p><br/>
    <p className='text-xl font-medium text-justify mx-3 mb-3'>Email:<a href='mailto:icctrda.congress@gmail.com' className='cursor-pointer'> icctrda.congress@gmail.com </a></p>
    </div>
  )
}

export default Venue